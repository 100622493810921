$color-foam: #E5F7FA;

html,
body {
    overflow-x: hidden;
}

body {
    width: 100%;
}

@media screen and (min-width: 768px) {
    .container-lg {
        max-width: 1272px;
    }
}


.bg-color-foam {
    background-color: $color-foam !important;
}

.hl-item,
.hl-item-oon {
    background: linear-gradient(to right, #FFD7B3 50%, #fff 50%) right;
    background-size: 250%;
    background-repeat: no-repeat;
}

.hl-item.is-highlighted,
.hl-item-oon.is-highlighted {    
    transition: .5s ease-in;
    background-position: left;
}

.msCompare .hl-item,
.msCompare .hl-item-oon {
    background: linear-gradient(to right, #B7ECB0 50%, #fff 50%) right;
    background-size: 250%;
    background-repeat: no-repeat;

    &.is-highlighted {    
        transition: .5s ease-in;
        background-position: left;
    }
}

.hl-cat {
    display: inline-flex;
    padding: 3px 8px;
    margin-left: -8px;
    transition: background-color 0s !important;
}

.hl-cat.is-highlighted {
    background-color: #FFD7B3;
}

.msCompare .hl-cat.is-highlighted {
    background-color: #B7ECB0;
}

.hl-flex {

    & > * + * {
        margin-top: 20px;

        @include media-query {
            margin-top: 0;
        }
    }

    @include media-query {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > * {
            position: relative;
            width: 50%;
        }

        & > *:last-child:before {
            content: '';
            display: block;
            position: absolute;
            left: -5px;
            top: 25%;
            width: 1px;
            height: 60%;
            background-color: #CBCCCD;
        }
    }
    
}

.compare-checklist-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
}

.compare-checklist {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-self: center;
    flex-direction: column;
    flex-wrap: wrap;

    li {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        &:before {
            content: '';
            width: 28px;
            height: 28px;
            background-image: url('../public/assets/icons/green-check.svg');
            background-size: cover;
            background-repeat: no-repeat;
        }
    }
}

.cell-covered {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    &:before {
        content: '';
        width: 28px;
        height: 28px;
        background-image: url('../public/assets/icons/green-check.svg');
        background-size: cover;
        background-repeat: no-repeat;
    }
    
}

.cell-not-covered {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    &:before {
        content: '';
        width: 28px;
        height: 28px;
        background-image: url('../public/assets/icons/red-x.svg');
        background-size: cover;
        background-repeat: no-repeat;
    }
    
}

.button-save {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 767px) {
        flex: none !important;
    }

    @include media-query(768px) {
        width: auto;
        height: auto;
    }

    img {

        @include media-query(768px) {
            margin-right: 10px;
            margin-left: -5px;
        }
    }
}

.button-save-text {
    display: none;

    .d-block {
        margin: 0 !important;
    }

    @include media-query {
        display: block;
    }
}

.plan-button-contain {
    
    & > * {
        flex: 1 1;
    }

    @include media-query {
        flex-wrap: wrap;
    }
}

.plan-compare-checkbox {
    @include media-query {
        padding: 20px;
        margin-top: -15px;
        margin-left: -15px;
        margin-right: -15px;
    }

    .uhc-checkbox__label {
        font-weight: 700;
    }

    .uhc-checkbox__visual {
        border: 1px solid #7D7F81;
    }
}

.plan-compare-checkbox__sticky {
    height: auto;
    padding: 20px;
    margin-left: -20px;
    width: 375px;

    @include media-query {
        display: none;
    }
}

.sticky-outer-wrapper.active .plan-compare-checkbox__sticky:not(.is-active) {
    height: 0;
    overflow: hidden;
    padding: 0;
    margin: 0 !important;
}

.sticky-outer-wrapper.active .plan-button-contain {
    display: none !important;

    &.is-active {
        display: flex !important;
    }
}

.uhc-accordion .uhc-slide-table__cell .uhc-link-button {
    width: 100%;
    padding: 17px 32px;
    border-radius: 8px;
    border: 1px solid #D9E9FA;
    background: #FAFCFF;

    & > span {
        justify-content: center;
    }

    span span {
        margin-left: 5px;
    }
}

.hl-flex--empty {

    .empty-cell {
        &:before {
            content: '';
            width: 14px;
            height: 2px;
            background: #999;
        }
    }

    > *:last-child {
        @include media-query {
            display: none;
        }
    }
    
    @include media-query {
        justify-content: center;
    }
}

.sub-accordions {
    margin-top: -30px;
    margin-bottom: 40px;

    & > *:last-child .uhc-accordion__header {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
}

.sub-accordion .uhc-accordion__header {
    border-right: 1px solid #DADBDC;
    border-bottom: 1px solid #DADBDC;
    border-left: 1px solid #DADBDC;
    background: #FAFCFF;
    padding-inline: 24px 20px !important;

    svg {
        display: none;
    }

    &:after {
        content: '';
        position: relative;
        top: 2px;
        display: block;
        width: 14px;
        height: 14px;
        background-image: url('../public/assets/icons/chevron-down-icon@2x.png');
        background-repeat: no-repeat;
        background-size: contain;
    }

    & > div {
        font-family: $font-family-primary;
        font-size: 20px;
        font-weight: 700;
    }
}

.sub-accordion.uhc-accordion--expanded .uhc-accordion__header:after {
    transform: rotate(180deg);
}

.uhc-slide-table .uhc-slide-table__cell.uhc-slide-table__cell--vcentered {
    vertical-align: top;

    @include media-query {
        vertical-align: middle;
    }
}

/* Highlight Banner */
.highlight-banner {
    margin-block: 32px;
    border-radius: 8px;
    background: #E5F8FB;
    display: flex;
    align-items: center;
    justify-content: center;
}

.highlight-banner__content {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
    padding: 16px 19px 21px;
    gap: 10px;

    @include media-query {
        padding-block: 16px;
    }
}

.highlight-banner__copy {
    font-size: 17px;
    font-weight: 700;

    @include media-query {
        font-size: 20px;
    }
}

.highlight-banner__button {
    display: flex;
    align-items: center;
    gap: 5px;
    appearance: none;
    border: 0;
    background: transparent;
    font-size: 17px;
    font-weight: 700;
    color: #196ECF;
    cursor: pointer;

    @include media-query {
        font-size: 20px;
    }
}

.highlight-banner__overlay {
    opacity: 0;
    position: fixed;
    box-shadow: rgba(33, 33, 33, 0) 0px 0px 1px 2px, rgba(33, 33, 33, 0.5) 0px 0px 0px 5000px;
    z-index: 10;
    pointer-events: none;

    &.is-instruction {
        opacity: 1;
    }
}

.highlight-banner__tooltip {
    position: absolute;
    top: calc(100% + 20px);
    left: 20px;
    right: 20px;
    background: #fff;
    border-radius: 4px;
    transform: translateY(-20px);
    transition: transform 0.3s;

    &.is-instruction {
        transform: translateY(0);
        transition: transform 0.3s;
    }
}

.highlight-banner__tooltip__content {
    padding: 16px;
    line-height: 1.3;

    &:before {
        content: '';
        display: block;
        width: 0;  
        height: 0;  
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #fff;
        position: absolute;
        top: -10px;
        left: 50%;
        transform: translate(-50%);
    }
}

.sticky-inner-wrapper .uhc-slide-table td.uhc-slide-table__cell {
    padding: 16px;

    @include media-query {
        padding: 32px 16px;
    }
}